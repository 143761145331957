import SlideshowContainer from '../../components/common/slideshow-container.vue'
import ListTable from '../../components/promotional_article/list-table.vue'
import queryString from 'query-string'

export default {
  name: 'promotional-articles-index-page-container',

  components: {
    SlideshowContainer,
    ListTable,
  },

  // props: {},

  data() {
    return {
      categoriesLoaded: false,
      currentTabIndex: 0,
    }
  },

  computed: {
    currentCategory() {
      return this.categories[this.currentTabIndex]
    },

    categories() {
      return this.$store.getters['categorySystems/allCategories']({
        depth: 2,
      })
    },

    queryString() {
      return queryString.parse(window.location.search)
    },
  },

  // created() {},
  mounted() {
    this.$store
      .dispatch(`categorySystems/fetchCategoriesByUsage`, {
        usage: 'promotional_article',
        sort: 'created_at',
      })
      .then((_) => {
        let categoryIndex = this.categories.findIndex((category) => category.id == this.queryString.category_id)

        this.currentTabIndex = categoryIndex > -1 ? categoryIndex : 0

        this.categoriesLoaded = true
      })
  },

  // methods: {}
}
