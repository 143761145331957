<template lang="pug">

.vc-return-authorization-refund-info
  h3.title {{ modelNameLocaleText('order/refund') }}
  refund-unit(v-for="refund in refunds"
              :key="refund.id"
              :refund="refund")

</template>

<script>
import RefundUnit from '../order_refund/unit.vue'

export default {
  components: {
    RefundUnit,
  },
  // mixins: [],
  props: {
    returnAuthorization: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    refunds() {
      return this.$store.getters['orders/allRefunds']
    },
  },
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
