<template lang="pug">

.vc-common-address-list
  b-loading(:active.sync="isLoading"
            :is-full-page="false")
  template(v-if="hasCommonAddresses")
    common-address-unit(v-for="(address, index) in commonAddresses"
                        :address="address"
                        :selected-address="selectedAddress"
                        :index="index"
                        :key="address.id"
                        @address-selected="addressSelectedHandler")
  template(v-else-if="!isLoading")
    .empty-state
      p
        b-icon(icon='times'
              size='is-large')
      p {{messageLocaleText('there_is_no_data_for_now')}}

</template>

<script>
import CommonAddressUnit from './unit.vue'

export default {
  components: {
    CommonAddressUnit,
  },
  // mixins: [],
  props: {
    selectedAddress: {
      type: [Object, Number],
      default: () => {
        return 0
      },
    },

    logisticType: {
      type: String,
      default: () => {
        return undefined
      },
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    commonAddresses() {
      return this.$store.getters['userCommonAddresses/allByLogisticType'](this.logisticType)
    },

    hasCommonAddresses() {
      return this.commonAddresses.length > 0
    },

    isLoading() {
      return this.$store.getters['users/isLoading']
    },
  },

  created() {
    if (!this.hasCommonAddresses) this.$store.dispatch('users/fetchCommonAddresses')
  },
  // mounted() {},
  methods: {
    addressSelectedHandler(address) {
      this.$emit('update:selectedAddress', address)
      this.$parent.close()
    },
  },
}
</script>
