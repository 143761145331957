<template lang="pug">

.vc-product-tag-filter(v-if="productTags.length > 0")
  span.text
    | {{ copyLocaleText('tag_filter') }} :
  b-checkbox-button(v-for="tag in productTags"
                    :key="tag.id"
                    v-model="tags"
                    type="is-odd"
                    :native-value="tag.name"
                    @input="tagSelectedHandler")
    .icon
      i.fa.fa-tag
    span {{ tag.name }}
</template>

<script>
import queryString from 'query-string'

export default {
  // components: {},
  // mixins: [],
  // props: {},

  data() {
    return {
      tags: [],
    }
  },

  computed: {
    productTags() {
      return this.$store.getters['productTags/all']
    },
  },

  created() {
    this.$store
      .dispatch('productTags/all')
      .then((_) => this.initTagFilters())
      .then((_) => this.$emit('update:tag-ready', true))
  },
  // mounted() {},
  methods: {
    tagSelectedHandler(tags) {
      this.$emit('update:tag-with', tags)
    },

    initTagFilters() {
      let decodedTagString = queryString.parse(window.location.search, { decode: false }).tag

      if (!decodedTagString) return

      this.tags = this.productTags
        .filter((tag) => {
          return decodedTagString
            .split(',')
            .map((tag) => decodeURIComponent(tag))
            .includes(tag.name)
        })
        .map((tag) => tag.name)

      this.tagSelectedHandler(this.tags)
    },
  },
}
</script>
