import moment from 'moment'
import FormBase from 'odd-form_object'

export default class UserChildForm extends FormBase {
  constructor(model) {
    super(model)

    if (!!this.birthday) this.birthday = new Date(this.birthday * 1000)
  }

  sync() {
    let editedModel = this.constructor.dataDumper(this, this.model, this.originalData)

    if (editedModel.birthday) editedModel.birthday = moment(editedModel.birthday).unix()

    return editedModel
  }
}
