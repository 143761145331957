<template lang="pug">

.vc-content-category-category-menu(:class="{ 'is-active': isMobileMenuShow }")
  .usage-unit(v-for="usage in usages"
              :key="usage.id")
    h3.label {{ usage.name }}
      .category-unit(v-for="category in childrenOf(usage)"
                     :key="category.id")
        .inner-wrapper(:class="{ 'is-active': currentCategory && currentCategory.id === category.id }"
                       @click="selectCategory(category)")
          span.name {{ category.name }}
          .icon
            i.fa.fa-chevron-right

        .sub-categories(v-if="currentCategory && currentCategory.id === category.id")
          sub-category-unit(v-for="subCategory in childrenOf(category)"
                            :key="subCategory.id"
                            :sub-category="subCategory"
                            :current-sub-category="currentSubCategory"
                            @sub-category-selected="currentSubCategorySelectedHandler")

</template>

<script>
import SubCategoryUnit from './sub-category-unit.vue'

export default {
  components: {
    SubCategoryUnit,
  },
  // mixins: [],
  props: {
    currentCategory: {
      type: Object,
      required: false,
    },

    currentSubCategory: {
      type: Object,
      required: false,
    },

    isMobileMenuShow: {
      type: Boolean,
      required: true,
    },

    defaultUsages: {
      type: Array,
      required: false,
      default() {
        return null
      },
    },
  },

  // data() {
  //   return {}
  // },

  computed: {
    usages() {
      return this.$store.getters['categorySystems/allCategories']({ depth: 1, defaultUsages: this.defaultUsages })
    },
  },
  // created() {},
  mounted() {
    this._ensureCategoriesLoaded()
  },

  methods: {
    childrenOf(category) {
      return this.$store.getters['categorySystems/childrenOf'](category)
    },

    selectCategory(category) {
      if (this.currentCategory && this.currentCategory.id === category.id) {
        this._tryUnselectTopCategory()
      } else {
        this.$emit('update:currentCategory', category)
      }
      this.$emit('update:currentSubCategory', undefined)
      this.$emit('update:isMobileMenuShow', false)
    },

    currentSubCategorySelectedHandler(subCategory) {
      this.$emit('update:currentSubCategory', subCategory)
      this.$emit('update:isMobileMenuShow', false)
    },

    _ensureCategoriesLoaded() {
      if (this.usages.length > 0) {
        this.$emit('categories-loaded')
      } else {
        this.$store
          .dispatch('categorySystems/fetchCategoriesByType', { type: 'content', sort: 'created_at' })
          .then((_) => {
            this.$emit('categories-loaded')
          })
      }
    },

    /**
     * 若已經沒有被選擇的子類別，則取消選擇主類別
     */
    _tryUnselectTopCategory() {
      if (!this.currentSubCategory) {
        this.$emit('update:currentCategory', undefined)
      }
    },
  },
}
</script>
