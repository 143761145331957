<template lang="pug">

.vc-sales-event-product-list
  b-loading(:active.sync="isProductsLoading"
            :is-full-page="false")
  b-tabs
    b-tab-item(:label="attributeLocaleText('sales_event', 'product_ships')")
      .products-wrapper
        product-unit(v-for="(product, index) in products"
                    :key="product.id"
                    :product="product"
                    :index="index"
                    :sales-event="salesEvent"
                    :list-location="listLocation"
                    @ga-impressed="impressedHandler")

      odd-pagination(:total="totalCount"
                  :current.sync="currentPage"
                  :per-page="pageSize"
                  size="is-small"
                  order="is-centered"
                  :rounded="true"
                  @change="onPageChange")

    b-tab-item(:label="attributeLocaleText('sales_event', 'description')")
      .editor-content(v-html="salesEvent.description")

</template>

<script>
import productImpressionMixin from '../mixins/product_impression_mixin.js'
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import ProductUnit from './product-unit.vue'
import OddPagination from '../common/odd-pagination.vue'

export default {
  components: {
    ProductUnit,
    OddPagination,
  },

  mixins: [productImpressionMixin, backendPaginateFilterSortAndSearchableMixin],

  props: {
    salesEvent: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      resourceType: 'products',
      currentPage: 1,
      pageSize: 12,
      sortOrder: 'asc',
      sortField: 'sales_event_ships.position',
      currentTab: 0,
      isProductsLoading: false,
    }
  },

  computed: {
    products() {
      return this.$store.getters['products/all']
    },

    listLocation() {
      return `Sales Event - ${this.salesEvent.name}`
    },

    listContainerOffsetTop() {
      return this.$el.offsetParent.offsetTop - 50 - 90
    },
  },
  // created() {},
  mounted() {
    this.fetchingInitialData()
  },

  methods: {
    fetchData(options) {
      this.currentPage = options.pageNumber
      this.isProductsLoading = true

      this.$store
        .dispatch(`salesEvents/fetchProducts`, {
          model: this.salesEvent,
          options: Object.assign(options, { replace: true }),
        })
        .then((_) => {
          this.isProductsLoading = false
        })
        .catch((_) => {
          this.isProductsLoading = false
        })
    },
  },
}
</script>
