<template lang="pug">

.vc-return-authorization-detail-unit

  .content-wrapper
    h4.section-title {{ modelNameLocaleText('return_authorization/item') }}
    .item-unit(v-for="returnItem in returnItems"
              :key="returnItem.shipment_item_id")
      .group
        .group.option-and-thumb
          .product-thumb
            img.img(:src="findVariantThumb(returnItem.variant.id)")
        .group.name-and-unit-price
          .product-name
            .state
              span.tag(:class="returnItem.receptionStateType") {{ aasmStateLocaleText('return_authorization/item', 'reception_state', returnItem.reception_state) }}
            .name {{ findProductName(returnItem.variant.id) }}
            .edit-records(v-if="returnItem.edit_records.length")
              edit-record-unit(:record="findLatestEditRecord(returnItem)")
          .unit-price(:class="{ 'is-canceled': returnItem.acceptance_state === 'rejected' }")
            label.label {{ attributeLocaleText('order/item', 'price') }}
            .price {{ toMoney(returnItem.amount).format() }}
      .group.applied-and-returned-quantity
        .quantity-info(v-if="returnItem.returned_quantity !== returnItem.apply_quantity")
          label.label {{ attributeLocaleText('return_authorization/item', 'apply_quantity') }}
          .quantity {{ returnItem.apply_quantity }}
        .quantity-info.returned-quantity(v-if="returnItem.returned_quantity")
          label.label {{ attributeLocaleText('return_authorization/item', 'returned_quantity') }}
          .quantity {{ returnItem.returned_quantity }}

    .extra-info
      .column
        h4.section-title {{ attributeLocaleText('return_authorization', 'reason_id') }}
        .reason
          span {{ returnAuthorization.reason }}
      .column
        h4.section-title {{ attributeLocaleText('return_authorization', 'note') }}
        .note(v-html="simpleFormat(returnAuthorization.note)")

    .return-total
      label.label {{ attributeLocaleText('return_authorization', 'total_amount') }}
      span.price {{ toMoney(returnAuthorization.total_amount).format() }}

</template>

<script>
import EditRecordUnit from '../return_authorization_item/edit-record-unit.vue'

export default {
  components: {
    EditRecordUnit,
  },
  // mixins: [],
  props: {
    returnAuthorization: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    returnItems() {
      return this.$store.getters['returnAuthorizations/allItems'].filter(
        (item) => item.return_authorization.id === this.returnAuthorization.id
      )
    },

    variants() {
      return this.$store.getters['productVariants/all']
    },
  },
  // created() {},
  // mounted() {},
  methods: {
    findVariantThumb(id) {
      return this.variants.find((variant) => variant.id === id).cover.thumb.url
    },

    findProductName(id) {
      return this.variants.find((variant) => variant.id === id).product_name
    },

    findLatestEditRecord(returnItem) {
      return returnItem.edit_records[returnItem.edit_records.length - 1]
    },
  },
}
</script>
