<template lang="pug">

.vc-promotional-article-list-table
  b-loading(:active.sync="isLoading"
            :is-full-page="false")

  .articles-wrapper(v-if="promotionalArticles.length > 0")
    article-card(v-for="(article, index) in promotionalArticles"
                 :key="index"
                 :promotional-article="article")

  .empty-state(v-else)
    .icon
      i.fa.fa-smile-o
    span {{ messageLocaleText('there_is_no_data_for_now') }}

  odd-pagination(:total="totalCount"
                 :current.sync="currentPage"
                 :per-page="pageSize"
                 size="is-small"
                 order="is-centered"
                 :rounded="true"
                 @change="onPageChange")

</template>

<script>
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import ArticleCard from './card.vue'
import OddPagination from '../common/odd-pagination.vue'

export default {
  components: {
    ArticleCard,
    OddPagination,
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  props: {
    category: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      resourceType: 'promotionalArticles',
      currentPage: 1,
      pageSize: 12,
      sortOrder: 'desc',
      sortField: 'published_at',
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['categorySystems/isLoading']
    },

    promotionalArticles() {
      return this.$store.getters['promotionalArticles/all']
    },

    listContainerOffsetTop() {
      return this.$el.offsetParent.offsetTop - 50 - 90
    },
  },
  // created() {},
  watch: {
    category: {
      handler() {
        this.fetchData(this.currentOptions)
        this.updateQueryString(this.currentOptions)
      },
    },
  },

  mounted() {
    this.fetchingInitialData()
  },

  methods: {
    additionalOptionsToQueryString(options) {
      let result = ''

      result += `&category_id=${this.category.id}`

      return result
    },

    fetchData(options) {
      this.currentPage = options.pageNumber

      this.$store.dispatch('categorySystems/fetchCategoryPromotionalArticles', {
        model: this.category,
        options,
      })
    },
  },
}
</script>
