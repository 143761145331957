<template lang="pug">

.vc-product-sort-options-selector
  .content-wrapper
    template(v-for="field in sortableFields")
      button.sort-option-unit(v-for="order in avaliableOrders"
                              :class="{ 'is-active': field === sortField && order === sortOrder }"
                              @click="updateSortOption(field, order)")
        span {{ actionLocaleText('sort_by', { name: enumLocaleText('product', 'sort_option', field) }) }}
        .icon
          i.fa(:class="sortOptionIcon(field, order)")

</template>

<script>
import Product from '../../../../shared/resource_models/product.js'

export default {
  // components: {},
  // mixins: [],
  props: {
    sortField: {
      type: String,
      required: true,
      validator: (value) => {
        return Product.sortableFields().includes(value)
      },
    },

    sortOrder: {
      type: String,
      required: true,
      validator: (value) => {
        return ['asc', 'desc'].includes(value)
      },
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    sortableFields() {
      return Product.sortableFields()
    },

    avaliableOrders() {
      return ['asc', 'desc']
    },
  },
  // created() {},
  // mounted() {},
  methods: {
    sortOptionIcon(field, order) {
      return {
        'fa-sort-amount-asc': field === 'master.sell_price_cents' && order === 'asc',
        'fa-sort-amount-desc': field === 'master.sell_price_cents' && order === 'desc',
        'fa-sort-alpha-asc': field === 'name' && order === 'asc',
        'fa-sort-alpha-desc': field === 'name' && order === 'desc',
        'fa-sort-numeric-asc': field === 'created_at' && order === 'asc',
        'fa-sort-numeric-desc': field === 'created_at' && order === 'desc',
      }
    },

    updateSortOption(field, order) {
      this.$emit('field-updated', field)
      this.$emit('order-updated', order)
      this.$emit('sort-option-updated', field, order)
      this.$parent.close()
    },
  },
}
</script>
