<template lang="pug">

.vc-product-info-card-mobile.is-mobile-only(v-if="product.isDataLoaded()")
  .product-info
    a.brand(:href="brandLink")
      | {{ product.brand_name }}
    .name
      | {{ product.name }}
  .price-info(:class="{ '-event-live': salesEvent.isLive() }")
    .label
      template(v-if="salesEvent.isLive()")
        countdown(:time="eventRemainingTime"
                  :interval="1000"
                  tag="span")
          template(slot-scope="props")
            span
              .icon
                i.fa.fa-bolt.fa
              span {{ copyLocaleText('event_price') }}
              br
              span.counter {{ copyLocaleText('remaining_time_counter', { day: props.days, hour: props.hours, minute: props.minutes, second: props.seconds }) }}
      template(v-else)
        span {{ attributeLocaleText('product', 'price') }}
    .price
      span(data-currency="TWD")
        | {{ toMoney(product.original_price).format() }}
      span(data-currency="TWD")
        | {{ eventPrice.format() }}
  .sticky-action-bar
    purchase-button(:sales-event="salesEvent"
                    :product="product")

</template>

<script>
import salesEventPricesMixin from '../mixins/sales_event_prices_mixin.js'
import PurchaseButton from './purchase-button.vue'
import Countdown from '@chenfengyuan/vue-countdown'

export default {
  components: {
    PurchaseButton,
    Countdown,
  },

  mixins: [salesEventPricesMixin],

  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    brandLink() {
      return `/brands/${this.product.brand_id}`
    },

    eventRemainingTime() {
      const currentTime = this.currentUnixTime()

      return (this.salesEvent.ended_at - currentTime) * 1000
    },

    _eventPriceCompareTarget() {
      return this.product
    },
  },
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
