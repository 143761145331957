import OrderItem from '../../resource_models/order_item'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => (use_type = 'normal') => {
  return state.result.map((id) => new OrderItem(state.entities[id])).filter((item) => item.use_type == use_type)
}

export const fromCurrentOrder = (state) => (use_type = 'normal') => {
  if (state.result && state.currentOrderId) {
    return all(state)(use_type).filter((item) => item.order.id === state.currentOrderId)
  } else {
    return []
  }
}

export const currentOrderId = (state) => {
  return state.currentOrderId
}

export const rawEntities = (state) => {
  return {
    entities: state.entities,
    result: state.result,
  }
}

export const find = (state) => (id) => {
  return new OrderItem(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
