import ProductSupportDocument from '../../resource_models/product_support_document'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new ProductSupportDocument(state.entities[id]))
}

export const find = (state) => (id) => {
  return new ProductSupportDocument(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
