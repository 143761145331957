import productImpressionMixin from '../../components/mixins/product_impression_mixin.js'
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import CategoryMenu from '../../components/category/sidebar-category-menu.vue'
import SlideshowContainer from '../../components/common/slideshow-container.vue'
import ProductList from '../../components/product/list.vue'

export default {
  name: 'products-index-page-container',

  components: {
    CategoryMenu,
    SlideshowContainer,
    ProductList,
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      selectedCategory: undefined,
      categoryMenuIsReady: false,
    }
  },

  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
}
