<template lang="pug">

.vc-product-list
  b-loading(:active.sync="isLoading"
            :is-full-page="false")

  .tools-wrapper
    search-box(@update:search-options="searchOptions.variants_with_master_sku_or_brand_name_or_name_cont = $event"
               @update:search-options-ready="searchOptionsReady = $event")
    sort-button(:sort-field="sortField"
                :sort-order="sortOrder"
                @sort-option-updated="sortOptionUpdatedHandler")

  .decoration-stripe.-one

  product-tag-filter(@update:tag-with="tagWith = $event"
                     @update:tag-ready="tagReady = $event")

  .decoration-stripe.-one.no-margin

  .list-wrapper
    product-card(v-for="(product, index) in products"
                  :product="product"
                  :index="index"
                  :key="product.id"
                  :list-location="listLocation"
                  @ga-impressed="impressedHandler")


  odd-pagination(:total="totalCount"
                 :current.sync="currentPage"
                 :per-page="pageSize"
                 size="is-small"
                 order="is-centered"
                 :rounded="true"
                 @change="onPageChange")

</template>

<script>
import productImpressionMixin from '../../components/mixins/product_impression_mixin.js'
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import SearchBox from '../../components/product/search-box.vue'
import SortButton from '../../components/product/sort-button.vue'
import ProductTagFilter from '../../components/product/tag-filter.vue'
import ProductCard from './card.vue'
import OddPagination from '../common/odd-pagination.vue'

export default {
  components: {
    SearchBox,
    SortButton,
    ProductTagFilter,
    ProductCard,
    OddPagination,
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin, productImpressionMixin],

  props: {
    category: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      resourceType: 'products',
      currentPage: 1,
      pageSize: 9,
      sortOrder: 'asc',
      sortField: 'created_at',
      tagReady: false,
      tagWith: [],
      searchOptionsReady: false,
      searchOptions: {
        variants_with_master_sku_or_brand_name_or_name_cont: '',
      },
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['productCategories/isLoading'] || this.$store.getters['products/isLoading']
    },

    products() {
      return this.$store.getters['products/all']
    },

    additionalOptions() {
      return {
        category_id: this.category ? this.category.id : null,
        tag: encodeURIComponent(this.tagWith.join(',')),
      }
    },

    listContainerOffsetTop() {
      return this.$el.offsetParent.offsetTop - 50 - 90
    },

    listLocation() {
      if (this.category) {
        let result = this.category.name
        if (this.currentSubCategory) result += ` > ${this.currentSubCategory.name}`

        return `Category - ${result}`
      } else {
        return 'Product Index'
      }
    },

    filterComponentsReady() {
      return this.searchOptionsReady && this.tagReady
    },
  },

  watch: {
    filterComponentsReady() {
      // 等待 tagFilter 與 searchBox 的資訊都從 child components 更新至 parent 時才一併送出資料請求
      this.fetchingInitialData()
    },

    category() {
      if (this.filterComponentsReady) this.processDataWithNewQuery()
    },

    tagWith() {
      if (this.filterComponentsReady) this.processDataWithNewQuery()
    },

    'searchOptions.variants_with_master_sku_or_brand_name_or_name_cont': {
      handler: function () {
        if (this.filterComponentsReady) this.processDataWithNewQuery()
      },
    },
  },
  // mounted() {},
  methods: {
    sortOptionUpdatedHandler(field, order) {
      this.onSort(field, order)
    },

    fetchData(options) {
      this.currentPage = options.pageNumber
      if (this.category) {
        return this._fetchCategoryProducts(options)
      } else {
        return this._fetchProducts(options)
      }
    },

    _fetchCategoryProducts(options) {
      return this.$store.dispatch(`productCategories/fetchProducts`, {
        model: this.$store.getters['productCategories/find'](this.category.id),
        options: Object.assign(options, {
          sub_categories_included: true,
        }),
      })
    },

    _fetchProducts(options) {
      return this.$store.dispatch('products/all', Object.assign(options))
    },

    additionalOptionsToQueryString(options) {
      let result = ''

      if (options.category_id) result += `&category_id=${options.category_id}`
      if (options.tag) result += `&tag=${decodeURIComponent(options.tag)}`

      return result
    },

    checkAdditionalOptionsFromUrl() {
      return {
        tag: encodeURIComponent(this.tagWith.join(',')),
      }
    },

    processDataWithNewQuery() {
      this.currentPage = 1
      this.fetchData(this.currentOptions)
      this.updateQueryString(this.currentOptions)
      this._scrollToListTop()
    },
  },
}
</script>
