import UserCreditCard from '../../resource_models/user_credit_card'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new UserCreditCard(state.entities[id]))
}

export const allByGatewayProviderAndPaymentType = (state) => (gatewayProvider = undefined, paymentType = undefined) => {
  return state.result
    .map((id) => new UserCreditCard(state.entities[id]))
    .filter((userCreditCard) => {
      return userCreditCard.gateway_provider === gatewayProvider && userCreditCard.payment_type == paymentType
    })
}

export const defaultForGatewayProviderAndPaymentType = (state) => (
  gatewayProvider = undefined,
  paymentType = undefined
) => {
  return allByGatewayProviderAndPaymentType(state)(gatewayProvider, paymentType).find(
    (userCreditCard) => userCreditCard.is_default
  )
}

export const find = (state) => (id) => {
  return new UserCreditCard(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
