import ActionConfirmService from '../../../../shared/services/action_confirm_service'
import DateAndNumber from '../../components/return_authorization/date-and-number.vue'
import StateList from '../../components/return_authorization/state-list.vue'
import DetailUnit from '../../components/return_authorization/detail-unit.vue'
import RefundInfo from '../../components/return_authorization/refund-info.vue'
import CommentButton from '../../components/order/comment-button.vue'

export default {
  name: 'user-rma-show-container',

  components: {
    DateAndNumber,
    StateList,
    DetailUnit,
    RefundInfo,
    CommentButton,
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      rmaNumber: undefined,
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    returnAuthorization() {
      return this.$store.getters['returnAuthorizations/findByNumber'](this.rmaNumber)
    },

    order() {
      return this.$store.getters['orders/find'](this.returnAuthorization.order_id)
    },

    queryString() {
      return window.location.search
    },
  },

  // created() {},

  mounted() {
    this.rmaNumber = this.$el.dataset.number
    this.$store.dispatch('users/getReturnAuthorization', this.rmaNumber)
  },

  methods: {
    confirmCancel() {
      new ActionConfirmService().confirm(this.cancel)
    },

    cancel() {
      this.$store.dispatch('returnAuthorizations/cancel', this.returnAuthorization)
    },
  },
}
