<template lang="pug">

form.vc-order-cvs-selector(@submit.prevent="")
  .section-label
    span {{ copyLocaleText('cvs_infomation') }}

  .form-section
    p {{ cvsStoreInfo.cvs_store_name }}
    p {{ cvsStoreInfo.cvs_address }}
    p {{ cvsStoreInfo.cvs_telephone }}

    b-field(:type="errors.errorClassAt('cvs_store_info')"
                       :message="errors.get('cvs_store_info')")

    button.button.is-odd.is-outlined(href="#"
                                     target="_blank"
                                     ref="noopenner noreferrer"
                                     @click.prevent="getParamsAndGoForSelect()")
      | {{ selectCvsBtnText }}

</template>

<script>
import queryString from 'query-string'

const MOBILE_IMAGE_BREAK_POINT = 769

export default {
  // components: {},
  // mixins: [],
  props: {
    selectedShippingMethodId: {
      type: [Number, String],
      required: true,
    },

    cartService: {
      type: Object,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },

    form: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    selectCvsBtnText() {
      return this.actionLocaleText('select_x', {
        x: this.enumLocaleText('shipping_method', 'sub_logistic_type', this.shippingType),
      })
    },

    shippingMethod() {
      return this.$store.getters['shippingMethods/find'](this.selectedShippingMethodId)
    },

    shippingType() {
      return this.shippingMethod.shipment_type_detail.shipping_type
    },

    expressMapParams() {
      return this.$store.getters['orders/expressMapParams']
    },

    expressMapApiBase() {
      return this.$store.getters['orders/expressMapApiBase']
    },

    expressMapUrl() {
      let url = ''
      for (let key in this.expressMapParams) {
        url += `${key}=${this.expressMapParams[key]}&`
      }

      return this.expressMapApiBase + '?' + url
    },

    errors() {
      return this.$store.getters['users/errors']
    },

    cvsStoreInfo() {
      return this.form.shipping_address.address.cvs_store_info
    },

    isMobile() {
      return this.$store.getters['windowWidth'] < MOBILE_IMAGE_BREAK_POINT
    },
  },

  mounted() {
    this.checkStoreInfoWithLogisticType()
    this.tryToRestoreContactInfo().then((_) => {
      this.syncCvsStoreInfo()
    })
  },

  methods: {
    syncCvsStoreInfo() {
      const cvsInfo = queryString.parse(window.location.search)

      let cvsInfoInsertable =
        this.shippingMethod.gateway_type != cvsInfo.gateway ||
        this.shippingType != cvsInfo.ExtraData ||
        this.order.order_state != 'items_confirmed'

      if (cvsInfoInsertable) return
      this.syncStoreInfoToFrom(cvsInfo)
      this.flagFormAsEdited()
      this.clearQuertString()
    },

    clearQuertString() {
      let cleanUri = window.location.origin + window.location.pathname

      window.history.replaceState({}, document.title, cleanUri)
    },

    checkStoreInfoWithLogisticType() {
      if (this.form.shipping_address.address.extra_data !== this.shippingType) {
        this.form.shipping_address.address.cvs_store_info = {}
        this.flagFormAsEdited()
      }
    },

    flagFormAsEdited() {
      this.$emit('form-edited')
    },

    syncStoreInfoToFrom(data) {
      this.form.shipping_address.address.cvs_store_info = {
        cvs_address: data['CVSAddress'],
        cvs_store_id: data['CVSStoreID'],
        cvs_store_name: data['CVSStoreName'],
        cvs_telephone: data['CVSTelephone'],
        logistics_sub_type: data['LogisticsSubType'],
        gateway: data['gateway'],
      }
      this.form.shipping_address.address.extra_data = data['ExtraData']

      this.errors.clear('cvs_store_info')
    },

    storeContactInfo() {
      let contactInfo = {
        email: this.form.email,
        shipping_address: this.form.shipping_address,
        billing_address: this.form.billing_address,
      }

      this.$vlf.setItem('cart-contact-info', contactInfo)
    },

    tryToRestoreContactInfo() {
      return this.$vlf.getItem('cart-contact-info').then((contactInfo) => {
        if (!contactInfo) return
        this.form.email = contactInfo.email
        this.form.shipping_address = contactInfo.shipping_address
        this.form.billing_address = contactInfo.billing_address

        this.$vlf.setItem('cart-contact-info', null)
      })
    },

    getParamsAndGoForSelect() {
      this.storeContactInfo()
      this.cartService
        .expressMap(this.order, {
          device: this.isMobile ? 'mobile' : null,
        })
        .then(() => {
          Turbolinks.visit(this.expressMapUrl)
        })
    },
  },
}
</script>
