<template lang="pug">

.vc-common-address-unit(:class="unitClasses"
                        @click="addressSelected")
  .index-indicator
    span.index {{ index + 1 }}
  .address-info
    .info-section.recipient
      span.label {{ attributeLocaleText('user/common_address', 'name') }}
      span.data {{ address.recipient }}
    .info-section.phone
      span.label {{ attributeLocaleText('user/common_address', 'phone') }}
      span.data {{ address.phone }}
    .info-section.company(v-if="address.hasCompanyInfo()")
      span.label {{ attributeLocaleText('user/common_address', 'company') }}
      span.data {{ address.company }}
    .info-section.ein(v-if="address.hasCompanyInfo()")
      span.label {{ attributeLocaleText('user/common_address', 'ein') }}
      span.data {{ address.ein }}
    .info-section.address.cvs-info(v-if="address.isCvsStoreInfo()")
      span.label {{ enumLocaleText('shipping_method', 'sub_logistic_type', address.address.extra_data) }}
      span.data
        | {{ attributeLocaleText('user/common_address', 'cvs_store_name') }}：
        | {{ cvsStoreInfo.cvs_store_name }}
      span.data
        | {{ attributeLocaleText('user/common_address', 'cvs_store_id') }}：
        | {{ cvsStoreInfo.cvs_store_id }}
      span.data
        | {{ attributeLocaleText('user/common_address', 'cvs_telephone') }}：
        | {{ cvsStoreInfo.cvs_telephone }}
      span.data
        | {{ attributeLocaleText('user/common_address', 'cvs_address') }}：
        | {{ cvsStoreInfo.cvs_address }}
    .info-section.address.cvs-info(v-if="address.isStoreInfo()")
      span.label {{ enumLocaleText('shipping_method', 'sub_logistic_type', address.address.extra_data) }}
      span.data
        | {{ attributeLocaleText('user/common_address', 'cvs_store_name') }}：
        | {{ storeInfo.name }}
      //- span.data
        | {{ attributeLocaleText('user/common_address', 'cvs_telephone') }}：
        | {{ storeInfo.cvs_telephone }}
      span.data
        | {{ attributeLocaleText('user/common_address', 'cvs_address') }}：
        | {{ address.storeFullAddress() }}
    .info-section.address(v-if="address.isNormalAddress()")
      span.label {{ attributeLocaleText('user/common_address', 'address') }}
      span.data {{ address.fullAddress() }}

</template>

<script>
import isEqual from 'lodash.isequal'
import cloneDeep from 'lodash.clonedeep'

export default {
  // components: {},
  // mixins: [],
  props: {
    address: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: false,
      default: () => {
        return 0
      },
    },

    selectedAddress: {
      type: [Object, Number],
      required: false,
      default: () => {
        return 0
      },
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    isSelected() {
      return isEqual(this.address, this.selectedAddress)
    },

    unitClasses() {
      return {
        selected: this.isSelected,
      }
    },

    cvsStoreInfo() {
      return this.address.address.cvs_store_info
    },

    storeInfo() {
      return this.address.address.store_info
    },
  },
  // created() {},
  // mounted() {},
  methods: {
    addressSelected() {
      this.$emit('address-selected', cloneDeep(this.address))
    },
  },
}
</script>
