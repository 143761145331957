<template lang="pug">

.vc-user-credit-card-list
  b-loading(:active.sync="isLoading"
            :is-full-page="false")
  template(v-if="userCreditCards.length > 0")
    .vc-user-credit-card-unit(v-for="(userCreditCard, index) in gatewayUserCreditCards"
                            :class="{selected: userCreditCard.is_default}"
                            @click="selectUserCreditCard(userCreditCard)")
      .index-indicator
        span.index {{ index + 1 }}
      .card-info
        .info-section.card_type
          span.label {{ attributeLocaleText('user/credit_card', 'card') }}
          span.data {{ userCreditCard.card_type }}
        .info-section.bank
          span.label {{ attributeLocaleText('user/credit_card', 'bank') }}
          span.data {{ userCreditCard.bank_name }}
        .info-section.card_no
          span.label {{ attributeLocaleText('user/credit_card', 'card_no') }}
          span.data {{ userCreditCard.fullCardNo() }}
      .card-info(@click.prevent.stop="removeCard(userCreditCard.id)")
        .info-section.close
          span X
    .vc-user-credit-card-unit(@click="selectOtherUserCreditCard")
      .card-info
        | {{ attributeLocaleText('user/credit_card', 'select_new_card') }}

  template(v-else-if="!isLoading")
    .empty-state
      p
        b-icon(icon='times'
              size='is-large')
      p {{messageLocaleText('there_is_no_data_for_now')}}

</template>

<script>
import ActionConfirmService from '../../../../shared/services/action_confirm_service'
import UserCreditCard from '../../../../shared/resource_models/user_credit_card.js'
import Form from 'odd-form_object'

export default {
  // mixins: [],
  components: {},
  props: {
    gatewayProvider: {
      type: String,
      required: true,
    },

    paymentType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    userCreditCards() {
      return this.$store.getters['userCreditCards/all']
    },

    gatewayUserCreditCards() {
      return this.$store.getters['userCreditCards/allByGatewayProviderAndPaymentType'](
        this.gatewayProvider,
        this.paymentType
      )
    },

    defaultGatewayUserCreditCard() {
      return this.gatewayUserCreditCards.find((userCreditCard) => userCreditCard.is_default)
    },
  },

  // created() {},

  mounted() {
    this._fetchUserCreditCards(false)
  },

  methods: {
    selectUserCreditCard(userCreditCard) {
      this._setDefaultUserCreditCard({ id: userCreditCard.id, is_default: true })
    },

    selectOtherUserCreditCard() {
      if (this.defaultGatewayUserCreditCard) {
        this._setDefaultUserCreditCard({ id: this.defaultGatewayUserCreditCard.id, is_default: false })
      } else {
        this.$parent.close()
      }
    },

    removeCard(id) {
      new ActionConfirmService({
        title: this.messageLocaleText('confirmations.are_you_sure_want_to_cancel_user_credit_card'),
        text: this.messageLocaleText('confirmations.this_action_can_not_retrieve'),
      }).confirm((_) => {
        this._destroyUserCreditCard(id).then((_) => {
          this.$parent.close()
        })
      })
    },

    _setDefaultUserCreditCard(data) {
      let userCreditCardForm = new Form(new UserCreditCard(data))
      this.$store.dispatch('userCreditCards/setDefault', userCreditCardForm.sync()).then((_) => {
        this._fetchUserCreditCards(true) /* 其他信用卡預設值會被修改，因此重新取得資料 */
        this.$parent.close()
      })
    },

    _destroyUserCreditCard(id) {
      let userCreditCard = new UserCreditCard({ id: id })
      return new Promise((resolve, reject) => {
        this.$store.dispatch('userCreditCards/destroy', userCreditCard).then((_) => {
          resolve()
          return this.$store.dispatch('addFlashMessage', [
            'success',
            this.messageLocaleText('resource_deleted_successfully', {
              resource: this.modelNameLocaleText('user/credit_card'),
            }),
          ])
        })
      })
    },

    _fetchUserCreditCards(force) {
      if (this.isUserSignedIn && (force || this.userCreditCards.length == 0))
        this.$store.dispatch('users/fetchCreditCards')
    },
  },
}
</script>
