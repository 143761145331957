<template lang="pug">

.vc-order-refund-unit
  .state
    span.tag(:class="refund.stateType") {{ aasmStateLocaleText('order/refund', 'state', refund.state) }}
  .refund-info(v-if="refund.hasRefundInfo || isFormShowable")
    .columns
      .column
        b-field(:label="attributeLocaleText('order/refund', 'recipient')"
                :type="errors.errorClassAt('recipient')"
                :message="errors.get('recipient')")
          b-input(type="text"
                  v-model="form.recipient"
                  @input="errors.clear('recipient')"
                  :disabled="refund.state === 'done'")
      .column
        b-field(:label="attributeLocaleText('order/refund', 'bank_name')"
                :type="errors.errorClassAt('bank_code')"
                :message="errors.get('bank_code')")
          b-input(type="text"
                  v-model="bankName"
                  @input="errors.clear('bank_code')"
                  :disabled="refund.state === 'done'")
      .column
        b-field(:label="attributeLocaleText('order/refund', 'bank_code')"
                :type="errors.errorClassAt('bank_code')"
                :message="errors.get('bank_code')")
          b-input(type="text"
                  v-model="bankCode"
                  @input="errors.clear('bank_code')"
                  :disabled="refund.state === 'done'")
    .columns
      .column
        b-field(:label="attributeLocaleText('order/refund', 'account')"
                :type="errors.errorClassAt('account')"
                :message="errors.get('account')")
          b-input(type="text"
                  v-model="form.account"
                  @input="errors.clear('account')"
                  :disabled="refund.state === 'done'")
    .options(v-if="refund.state === 'pending'")
      button.button.is-default.is-small(@click="updateRefundInfo")
        .icon
          i.fa.fa-send
        span {{ actionLocaleText('submit') }}
  .amount
    label.label {{ attributeLocaleText('order/refund', 'amount') }}
    span.price {{ toMoney(refund.amount).format() }}

</template>

<script>
import Form from 'odd-form_object'

export default {
  // components: {},
  // mixins: [],
  props: {
    refund: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: new Form(this.refund),
      bankName: '',
      bankCode: '',
    }
  },

  computed: {
    errors() {
      return this.refund.errors
    },

    isFormShowable() {
      return window.location.search.includes('form=1') && this.refund.state === 'pending'
    },

    composedBankCode() {
      return `${this.bankName}|>${this.bankCode}`
    },
  },

  created() {
    if (!this.form.bank_code) return

    const data = this.form.bank_code.split('|>')

    this.bankName = data[0]
    this.bankCode = data[1]
  },
  // mounted() {},
  methods: {
    updateRefundInfo() {
      this.form.bank_code = this.composedBankCode
      this.$store.dispatch('orders/updateRefundInfo', this.form.sync()).then((_) => {
        return this.$store.dispatch('addFlashMessage', [
          'success',
          this.messageLocaleText('resource_updated_successfully', {
            resource: this.modelNameLocaleText('order/refund'),
          }),
        ])
      })
    },
  },
}
</script>
