<template lang="pug">

.vc-order-back-to-store-button.button.is-outlined.is-odd.back-to-store(@click="backToStore") {{ actionLocaleText('back_to_store') }}

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  // props: {},
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  methods: {
    backToStore() {
      this.$vlf.getItem('redirect-path').then((path) => {
        Turbolinks.visit(path || '/')
      })
    },
  },
}
</script>
