import RmaApplyWizard from '../../components/return_authorization/apply-wizard.vue'

export default {
  name: 'guest-order-apply-rma-container',

  components: {
    RmaApplyWizard,
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      orderToken: undefined,
    }
  },

  computed: {
    order() {
      return this.$store.getters['orders/find'](this.orderToken)
    },
  },

  // created() {},

  mounted() {
    this.orderToken = this.$el.dataset.order
    this.jwt = this.$el.dataset.jwt
    this.$store.dispatch('users/getGuestOrder', {
      token: this.orderToken,
      jwt: this.jwt,
    })
  },

  // methods: {}
}
