<script>
import ProductCard from './product-card.vue'
import ProductLink from './product-link.vue'
import AreaLink from './area-link.vue'
import ProductNameTrigger from './product-name-trigger.vue'

export default {
  components: {
    ProductCard,
    ProductLink,
    AreaLink,
    ProductNameTrigger,
  },
  // mixins: [],
  // props: {},
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
