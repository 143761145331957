<template lang="pug">

.vc-order-option-buttons
  //- Comment
  comment-button(:order="order"
                 :class="isOnlyButtonState")

  //- Detail Link
  a.button.is-outlined.is-default(v-if="showDetailLink"
                                  :href="orderDetailPath") {{ copyLocaleText('order_detail') }}

  //- Cancel
  a.button.is-outlined.is-default(v-if="order.canCancelBy(currentUser)"
                                  @click="confirmCancel") {{ actionLocaleText('cancel_order') }}

  //- Apply RMA
  a.button.is-outlined.is-default(v-if="order.canApplyForRma()"
                                  :href="applyRmaPath") {{ actionLocaleText('apply_rma') }}

  //- Payment
  .button.is-outlined.is-odd(v-if="canMakePayment"
                             @click="makePayment"
                             :class="{ 'is-loading': isProcessing }") {{ actionLocaleText('make_payment') }}
  form(:action="gatewayApiBase"
       method="post"
       ref="payment-form")

</template>

<script>
import queryString from 'query-string'
import ActionConfirmService from '../../../../shared/services/action_confirm_service.js'
import paymentButtonMixin from '../mixins/payment_button_mixin.js'
import CommentButton from './comment-button.vue'

export default {
  components: {
    CommentButton,
  },

  mixins: [paymentButtonMixin],

  props: {
    showDetailLink: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      isOnlyOneOption: false,
    }
  },

  computed: {
    canMakePayment() {
      return this.order.canMakePayment() && ['checkout', 'failed'].includes(this.payment.state)
    },

    payment() {
      return this.$store.getters['orderPayments/find'](this.order.payment.id)
    },

    orderDetailPath() {
      return `/user/orders/${this.order.id}`
    },

    applyRmaPath() {
      if (window.location.pathname.includes('/guest/orders/')) {
        const params = queryString.parse(window.location.search)
        return `/guest/apply_rma/${this.order.email}?token=${params.token}`
      } else {
        return `/user/orders/${this.order.id}/apply_rma`
      }
    },

    isOnlyButtonState() {
      return {
        'is-alone': this.isOnlyOneOption,
      }
    },
  },
  // created() {},
  mounted() {
    this._detactButtonLength()
  },

  methods: {
    confirmCancel() {
      new ActionConfirmService({
        title: this.messageLocaleText('confirmations.are_you_sure_want_to_cancel_order'),
        text: this.messageLocaleText('confirmations.this_action_can_not_retrieve'),
      }).confirm(this.cancelOrder)
    },

    cancelOrder() {
      this.$store
        .dispatch('orders/cancel', this.order)
        .then((_) => {
          return this.$emit('order-canceled')
        })
        .then((_) => {
          return this.$store.dispatch('addFlashMessage', [
            'success',
            this.messageLocaleText('order_canceled_successfully'),
          ])
        })
    },

    _detactButtonLength() {
      if (this.showDetailLink) return

      if (this.$el.querySelectorAll('.button').length === 1) {
        this.isOnlyOneOption = true
      }
    },
  },
}
</script>
