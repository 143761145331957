import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import OrderFilters from '../../components/dashboard/order-filters.vue'
import OrderSummaryUnit from '../../components/order/summary-unit.vue'
import ShoppingCartService from '../../../../shared/services/shopping_cart_service.js'
import OddPagination from '../../components/common/odd-pagination.vue'

export default {
  name: 'user-order-list-container',

  components: {
    OrderFilters,
    OrderSummaryUnit,
    OddPagination,
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  // props: {},

  data() {
    return {
      resourceType: 'orders',
      currentPage: 1,
      pageSize: 20,
      sortOrder: 'desc',
      sortField: 'completed_at',
      currentFilter: 0,
      availableFilters: [
        'completed',
        'waiting_for_payment',
        'waiting_for_shipment',
        'shipped',
        'canceled',
        'waiting_for_refund',
      ],
      isSearchOptionsOpen: false,
      searchOptions: {},
      cartService: new ShoppingCartService(this.$vlf, this.$store),
      orderIds: [],
    }
  },

  computed: {
    orders() {
      return this.$store.getters['orders/all'].filter((order) => this.orderIds.includes(order.id))
    },

    hasOrders() {
      return this.orders.length > 0
    },
  },

  // created() {},

  mounted() {
    this.fetchingInitialData()
  },

  methods: {
    fetchData(options) {
      this.currentPage = options.pageNumber
      this.$store.dispatch(`users/fetchOrders`, options).then((response) => {
        this.orderIds = response.data.data.map((order) => order.id)
      })
    },

    filterUpdatedHandler(filterName) {
      const indexOfUpdatedFilter = this.availableFilters.indexOf(filterName)

      this.currentFilter = indexOfUpdatedFilter
      this.currentPage = 1
      this.filterOnChangeHandler(indexOfUpdatedFilter)
    },
  },
}
