<template lang="pug">

.vc-distribution-base-brand-list
  .brands-wrapper
    summary-unit(v-for="brand in brands"
                 :brand="brand"
                 :key="brand.id")

  odd-pagination(:total="totalCount"
                 :current.sync="currentPage"
                 :per-page="pageSize"
                 size="is-small"
                 order="is-centered"
                 :rounded="true"
                 @change="onPageChange")

</template>

<script>
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import SummaryUnit from '../brand/summary-unit.vue'
import oddPagination from '../common/odd-pagination.vue'

export default {
  components: {
    SummaryUnit,
    oddPagination,
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  props: {
    distributionBase: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      resourceType: 'brands',

      pageSize: 10,
      sortOrder: 'asc',
      sortField: 'name',
    }
  },

  computed: {
    brands() {
      return this.$store.getters['brands/all']
    },
  },
  // created() {},
  mounted() {
    this.fetchingInitialData()
  },

  methods: {
    fetchData(options) {
      this.currentPage = options.pageNumber

      this.$store.dispatch('distributionBases/fetchBrands', {
        model: this.distributionBase,
        options,
      })
    },
  },
}
</script>
