<template lang="pug">

.vc-dashboard-child-form
  .section-label
    .icon
      i.fa.fa-child
    span {{ modelNameLocaleText('user/child') }}
    span.index(v-if="humanIndex") {{ humanIndex }}

  .form-section.child-field.has-sub-fields
    b-field(:label="attributeLocaleText('user/child', 'name')"
            :type="errors.errorClassAt('name')"
            :message="errors.get('name')")
      b-input(type="name"
              v-model="form.name"
              :disabled="isDisabled"
              @input="errors.clear('name')")

    b-field(:label="attributeLocaleText('user/child', 'nick_name')"
            :type="errors.errorClassAt('nick_name')"
            :message="errors.get('nick_name')")
      b-input(type="nick_name"
              v-model="form.nick_name"
              :disabled="isDisabled"
              @input="errors.clear('nick_name')")

    b-field(:label="attributeLocaleText('user/child', 'gender')"
            :type="errors.errorClassAt('gender')"
            :message="errors.get('gender')")
      b-select(v-model="form.gender"
               autocomplete="gender"
               name="gender"
               :class="{'is-disabled': isDisabled}"
               :disabled="isDisabled"
               @input="errors.clear('gender')")
        option(value="male") {{ enumLocaleText('user/child', 'gender', 'male') }}
        option(value="female") {{ enumLocaleText('user/child', 'gender', 'female') }}

    b-field(:label="attributeLocaleText('user/child', 'birthday')"
            :type="errors.errorClassAt('birthday')"
            :message="errors.get('birthday')")
      b-datepicker(v-model="form.birthday"
                     :disabled="isDisabled"
                     :max-date="new Date()"
                     @input="errors.clear('birthday')")

    .option-buttons
      //- display mode
      template(v-if="isDisabled")
        button.button.delete-button.is-danger.is-circle(@click="confirmDelete"
                                                        :class="{'is-loading': isLoading}")
          .icon
            i.fa.fa-close
        button.button.edit-button.is-info.is-circle(@click="editFrom")
          .icon
            i.fa.fa-pencil

      //- edit mode
      template(v-else)
        button.button.cancel-button(@click="cancelForm")
          | {{ actionLocaleText('cancel') }}
        button.button.submit-button.is-in-mommy(@click="submitForm"
                                                :class="{'is-loading': isLoading}")
          | {{ actionLocaleText('submit') }}

</template>

<script>
import ActionConfirmService from '../../../../shared/services/action_confirm_service.js'
import UserChild from '../../../../shared/resource_models/user_child.js'
import UserChildForm from '../../../../shared/forms/user_child_form.js'

export default {
  // components: {},
  // mixins: [],
  props: {
    child: {
      type: Object,
      required: false,
      default: () => {
        return new UserChild()
      },
    },

    humanIndex: {
      type: Number,
      required: false,
    },

    displayMode: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      form: new UserChildForm(this.child),
      isDisabled: !!this.displayMode,
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    modelName() {
      return this.modelNameLocaleText('user/child')
    },

    errors() {
      return this.form.model.errors
    },

    flashMessage() {
      const action = this.form.model.isNewRecord() ? 'added' : 'updated'

      return ['success', this.messageLocaleText(`resource_${action}_successfully`, { resource: this.modelName })]
    },
  },
  // created() {},

  mounted() {
    if (!this.form.gender) this.form.gender = 'male'
    if (!this.form.user_id) this.form.model.user_id = this.currentUser.id
  },

  methods: {
    confirmDelete() {
      new ActionConfirmService({
        title: this.messageLocaleText('confirmations.are_you_sure_want_to_delete_for', {
          model: this.modelName,
        }),
        text: this.messageLocaleText('confirmations.this_action_can_not_retrieve'),
      }).confirm(this.deleteChild)
    },

    deleteChild() {
      this.$store.dispatch('users/destroyChild', this.child).then((_) => {
        this.$store.dispatch('addFlashMessage', [
          'success',
          this.messageLocaleText('resource_deleted_successfully', { resource: this.modelName }),
        ])
      })
    },

    editFrom() {
      this.isDisabled = false
    },

    cancelForm() {
      this.isDisabled = true
      this.$emit('form-canceled')
    },

    submitForm() {
      this.$store
        .dispatch('users/saveChild', this.form.sync())
        .then((_) => {
          this.$store.dispatch('addFlashMessage', this.flashMessage)
        })
        .then((_) => {
          let eventType = this.form.model.isNewRecord() ? 'child-added' : 'child-updated'

          this.isDisabled = true
          this.$emit(eventType)
        })
    },
  },
}
</script>
