<template lang="pug">
.vc-dashboard-password-form
  .section-label
    span
      .icon
        i.fa.fa-key
      | {{ actionLocaleText('change_password') }}
  .form-section.has-sub-fields
    b-field.is-full(:label="attributeLocaleText('user', 'current_password')"
                    :type="errors.errorClassAt('password')"
                    :message="errors.get('password')")
      b-input(type="password"
              v-model="form.password"
              @input="errors.clear('password')")

    b-field(:label="attributeLocaleText('user', 'new_password')"
            :type="errors.errorClassAt('new_password')"
            :message="errors.get('new_password')")
      b-input(type="password"
              v-model="form.new_password"
              @input="errors.clear('new_password')")
    b-field(:label="attributeLocaleText('user', 'password_confirmation')"
            :type="errors.errorClassAt('password_confirmation')"
            :message="errors.get('password_confirmation')")
      b-input(type="password"
              v-model="form.password_confirmation"
              @input="errors.clear('password_confirmation')")

  button.button.is-odd.is-block(@click="submitForm"
                                     :class="{'is-loading': isLoading}")
    | {{ actionLocaleText('submit') }}

</template>

<script>
import UserForm from '../../../../shared/forms/user_form.js'

export default {
  // components: {},
  // mixins: [],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: new UserForm(this.user),
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    errors() {
      return this.form.model.errors
    },
  },
  // created() {},
  // mounted() {},
  methods: {
    submitForm() {
      this.$store.dispatch('users/save', this.form.sync()).then(() => {
        return this.$store.dispatch('addFlashMessage', [
          'success',
          this.messageLocaleText('password_update_successfully'),
        ])
      })
    },
  },
}
</script>
