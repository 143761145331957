<template lang="pug">

.vc-category-sidebar-category-menu
  b-loading(:active.sync="isLoading"
            :is-full-page="false")

  .decoration-stripe.-two.no-margin
  a.menu-trigger(href="#"
                 @click.prevent="menuTrigger")
    .icon
      img(:src="squaresIcon")
    .en(v-if="!selectedCategory || !isMobile") {{ copyLocaleText('all_x', { x: modelNameLocaleText('category_system/category') }) }}
    .text.zh(v-if="currentCategory && isMobile")
      | {{ currentCategory.name }}
    .text.sub-category-name(v-if="currentSubCategory && isMobile")
      | {{ currentSubCategory.name }}
  .decoration-stripe.-two.no-margin

  .category-unit(v-for="category in topLevelCategories"
                 :key="category.id")
    .inner-wrapper(:class="{ 'is-active': currentCategory && currentCategory.id === category.id }"
                   @click="selectCategory(category)")
      span.name {{ category.name }}
      .icon(v-if="subCategories(category).length > 0")
        i.fa.fa-chevron-right

    transition(enter-active-class="animated fadeIn")
      .sub-categories(v-if="currentCategory && currentCategory.id === category.id")
        .sub-category-unit(v-for="subCategory in currentSubCategories"
                            :key="subCategory.id"
                            :class="{ 'is-active': currentSubCategory && currentSubCategory.id === subCategory.id }"
                            href="#"
                            @click.prevent="selectSubCategory(subCategory)")
          span.name {{ subCategory.name }}
</template>

<script>
import queryString from 'query-string'
import squaresIcon from '../../../images/squares.svg'

const MOBILE_IMAGE_BREAK_POINT = 769

export default {
  // components: {},
  // mixins: [],
  props: {
    categoryType: {
      type: String,
      required: true,
    },

    depth: {
      type: Number,
      required: false,
      default: () => {
        return 2
      },
    },
  },

  data() {
    return {
      currentCategory: undefined,
      currentSubCategory: undefined,
    }
  },

  computed: {
    isMobile() {
      // workaround for safari can't get window width from vuex at first time loading
      return (this.$store.getters['windowWidth'] || window.innerWidth) < MOBILE_IMAGE_BREAK_POINT
    },

    squaresIcon() {
      return squaresIcon
    },

    selectedCategory() {
      return this.currentSubCategory || this.currentCategory
    },

    mobileMenuStates() {
      return this.$store.getters['mobileMenuStates']
    },

    isfetchDataFromCategory() {
      return !!this.category
    },

    childOfRootCategory() {
      return this.$store.getters['categorySystems/categoriesByUsage'](this.categoryType)[0]
    },

    topLevelCategories() {
      let childOfRootCategory = this.childOfRootCategory

      if (this.categoryType === 'product') return this.$store.getters['productCategories/allTopLevel']
      if (!childOfRootCategory) return []

      return this.$store.getters['categorySystems/allCategories']({
        depth: this.depth,
      }).filter((category) => childOfRootCategory.id === String(category.parent_id))
    },

    currentSubCategories() {
      if (!this.currentCategory) return []

      return this.subCategories(this.currentCategory)
    },

    isLoading() {
      if (this.mobileMenuStates['activatedCategoryType'] === 'product') {
        return this.$store.getters['productCategories/isLoading']
      } else {
        return this.$store.getters['categorySystems/isLoading']
      }
    },
  },
  // created() {},
  mounted() {
    if (this.topLevelCategories.length > 0) return this.updateCategoryFromUrl()

    if (this.categoryType === 'product') {
      this.$store
        .dispatch('productCategories/all', {
          sort: 'position',
        })
        .then(() => this.updateCategoryFromUrl())
    } else {
      this.$store
        .dispatch(`categorySystems/fetchCategoriesByType`, {
          type: this.categoryType,
          sort: 'created_at',
          noReplace: true,
        })
        .then(() => this.updateCategoryFromUrl())
    }
  },

  methods: {
    updateCategoryFromUrl() {
      let updateFinalResult = () => {
        this.updateSelectedCategory()
        this.$emit('update:category-loaded', true)
      }

      let categoryId = queryString.parse(window.location.search)['category_id']

      if (!categoryId) return updateFinalResult()

      this.currentCategory = this.topLevelCategories.find((category) => category.id === categoryId)

      if (!this.currentCategory) {
        if (this.categoryType === 'product') {
          this.currentSubCategory = this.$store.getters['productCategories/all'].find(
            (category) => category.id === categoryId
          )
        } else {
          this.currentSubCategory = this.$store.getters['categorySystems/allCategories']({
            depth: this.depth + 1,
          }).find((category) => category.id === categoryId)
        }

        this.currentCategory = this.topLevelCategories.find(
          (category) => category.id === String(this.currentSubCategory.parent_id)
        )
      }

      updateFinalResult()
    },

    selectCategory(category) {
      if (this.currentCategory && this.currentCategory.id === category.id) {
        this._unSelectTopCategory()
      } else {
        this.currentCategory = category
      }

      this.currentSubCategory = undefined
      this.updateSelectedCategory()
    },

    selectSubCategory(category) {
      this.currentSubCategory = category
      this.updateSelectedCategory()
    },

    updateSelectedCategory() {
      this.$store.dispatch(
        'toggleMobileMenu',
        Object.assign(this.mobileMenuStates, {
          selectedCategoryType: this.categoryType,
        })
      )
      this.$emit('update:selected-category', this.selectedCategory)
    },

    resetSelectedCategory() {
      this.currentCategory = this.currentSubCategory = undefined
      this.updateSelectedCategory()
    },

    menuTrigger() {
      if (!this.isMobile) return this.resetSelectedCategory()

      this.$store.dispatch(
        'toggleMobileMenu',
        Object.assign(this.mobileMenuStates, {
          isMobileMenuActive: true,
          isCategoriesSelectorActive: true,
          activatedCategoryType: this.categoryType,
          isSkippingRootMenuToClose: true,
        })
      )
    },

    subCategories(currentCategory) {
      if (this.categoryType === 'product') {
        return this.$store.getters['productCategories/all'].filter(
          (category) => String(category.parent_id) === currentCategory.id
        )
      } else {
        return this.$store.getters['categorySystems/allCategories']()
          .filter((category) => String(category.parent_id) === currentCategory.id)
          .sort((a, b) => a.lft - b.lft)
      }
    },

    /**
     * 若已經沒有被選擇的子類別，則取消選擇主類別
     */
    _unSelectTopCategory() {
      if (!this.currentSubCategory) {
        this.currentCategory = undefined
      }
    },
  },
}
</script>
