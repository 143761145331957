<template lang="pug">

.vc-return-authorization-apply-wizard
  number-and-placed-date(:order="order")
  comment-button(:order="order")

  template(v-if="isFormReady")
    template(v-if="step === 1")
      shipment-detail(v-for="(shipment, index) in shipments"
                      :shipment="shipment"
                      :form="form"
                      :index="index"
                      :key="shipment.id")
      .odd-container.options
        button.button.is-odd(:disabled="this.form.items.length < 1"
                             @click="nextStep") {{ actionLocaleText('next_step') }}

    template(v-if="step === 2")
      rma-form(:form="form")
      .odd-container.options
        button.button.is-default(@click="prevStep") {{ actionLocaleText('prev_step') }}
        button.button.is-odd(:disabled="!isFormDone"
                             @click="submit") {{ actionLocaleText('submit') }}

</template>

<script>
import queryString from 'query-string'
import Form from 'odd-form_object'
import ReturnAuthorization from '../../../../shared/resource_models/return_authorization.js'
import NumberAndPlacedDate from '../order/number-and-placed-date.vue'
import ShipmentDetail from '../order_shipment/detail.vue'
import RmaForm from './form.vue'
import CommentButton from '../order/comment-button.vue'

export default {
  components: {
    NumberAndPlacedDate,
    ShipmentDetail,
    RmaForm,
    CommentButton,
  },
  // mixins: [],
  props: {
    order: {
      type: Object,
      required: true,
    },

    returnAuthorization: {
      type: Object,
      required: false,
      default: () => {
        return new ReturnAuthorization()
      },
    },
  },

  data() {
    return {
      step: 1,
      form: new Form(this.returnAuthorization),
      isFormReady: false,
    }
  },

  computed: {
    shipments() {
      return this.$store.getters['orderShipments/all']
    },

    isFormDone() {
      return !!this.form.reason_id && !!this.form.note && this.form.note.length >= 2
    },
  },
  // created() {},
  mounted() {
    if (this.form.items === undefined) this.form.items = []
    this.isFormReady = true
  },

  methods: {
    nextStep() {
      this.step += 1
    },

    prevStep() {
      this.step -= 1
    },

    submit() {
      this.$store
        .dispatch('users/applyRma', {
          orderToken: this.order.id,
          returnAuthorization: this.form.sync(),
        })
        .then((response) => {
          if (window.location.pathname.includes('/guest/apply_rma/')) {
            const params = queryString.parse(window.location.search)
            Turbolinks.visit(
              `/guest/return_authorizations/${response.data.data.attributes.number}?created=1&token=${params.token}`
            )
          } else {
            Turbolinks.visit(`/user/return_authorizations/${response.data.data.attributes.number}?created=1`)
          }
        })
    },
  },
}
</script>
