import * as types from './mutation-types'
import MutationHelpers from '../mutation_helpers'

const helpers = new MutationHelpers('orders')

export default {
  [types.FETCH_ORDERS_SUCCESS](state, response) {
    helpers.replaceEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_RELATED_ORDERS_SUCCESS](state, response) {
    helpers.storeResourcesToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_ORDER_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)
    helpers.pushResourceToResult(state, response)
    helpers.replaceSpecialEntities(state, response, 'adjustment', 'adjustments')

    state.isCallingAPI = false
  },

  [types.GET_UPDATED_ORDER_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.ADD_ORDER_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)
    helpers.pushResourceToResult(state, response)

    state.isCallingAPI = false
  },

  [types.UPDATE_ORDER_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.DELETE_ORDER_SUCCESS](state, id) {
    helpers.removeOneResourceFromEntities(state, id)

    state.isCallingAPI = false
  },

  [types.FETCH_PAYMENT_PARAMS_SUCCESS](state, response) {
    state.paymentParams = response.data.data
    state.gatewayApiBase = response.data.api_base

    state.isCallingAPI = false
  },

  [types.FETCH_EXPRESS_MAP_PARAMS_SUCCESS](state, response) {
    state.expressMapParams = response.data.data
    state.expressMapApiBase = response.data.api_base

    state.isCallingAPI = false
  },

  [types.API_REQUEST_START](state, action = 'not provided') {
    state.isCallingAPI = true
  },

  [types.API_REQUEST_SUCCESS](state, action = 'not provided') {
    state.isCallingAPI = false
  },

  [types.API_REQUEST_FAIL](state, errors) {
    state.errors.record(errors)
    state.isCallingAPI = false
  },

  [types.FETCH_ORDER_ADJUSTMENTS_SUCCESS](state, response) {
    helpers.replaceSpecialEntities(state, response, 'adjustment', 'adjustments')
  },

  [types.FETCH_ORDER_REFUNDS_SUCCESS](state, response) {
    helpers.replaceSpecialEntities(state, response, 'refund', 'order_refunds')
  },

  [types.FETCH_ORDER_INVOICES_SUCCESS](state, response) {
    helpers.replaceSpecialEntities(state, response, 'invoice', 'order_invoices')
  },

  [types.FETCH_ORDER_CREDIT_NOTES_SUCCESS](state, response) {
    helpers.replaceSpecialEntities(state, response, 'creditNote', 'order_credit_notes')
  },

  [types.GET_ORDER_REFUND_SUCCESS](state, response) {
    helpers.storeOneResourceToSpecialEntities(state, response, 'refund', 'order_refunds')

    state.isCallingAPI = false
  },
}
