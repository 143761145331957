<template lang="pug">

.vc-promotional-article-product-card(:class="`product-${productId}`")
  b-loading(:active.sync="isLoading"
            :is-full-page="false")

  .raw-data(ref="raw-data")
    slot

  template(v-if="!isLoading")
    template(v-if="isNotFound")
      .cover
        .not-found-message
          span 商品目前無法選購
        img(:src="actuallyProductInfo.productCover"
            :alt="actuallyProductInfo.productName")
      .brand
        span.text {{ actuallyProductInfo.productBrand }}
      .name
        span.text {{ actuallyProductInfo.productName }}
    template(v-if="!isNotFound")
      sales-event-product-unit(v-if="salesEventId"
                               :product="product"
                               :sales-event="salesEvent")
      product-card(v-else
                   :product="product")

</template>

<script>
import inView from 'in-view'
import productCard from '../product/card.vue'
import SalesEventProductUnit from '../sales_event/product-unit.vue'

export default {
  components: {
    productCard,
    SalesEventProductUnit,
  },
  // mixins: [],
  props: {
    productId: {
      type: String,
      required: true,
    },
    productBrand: {
      type: String,
      required: true,
    },
    productName: {
      type: String,
      required: true,
    },
    productCover: {
      type: String,
      required: true,
    },
    salesEventId: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      isLoading: true,
      // flag of `product/find` action result is 404, if value equal to true usally means that product not available for now.
      isNotFound: false,
    }
  },

  computed: {
    product() {
      return this.$store.getters['products/find'](this.productId)
    },

    actuallyProductInfo() {
      return {
        productBrand: this.product.brand_name || this.productBrand,
        productName: this.product.name || this.productName,
        productCover: this.product.cover?.square?.url || this.productCover,
      }
    },

    salesEvent() {
      return this.$store.getters['salesEvents/find'](this.salesEventId)
    },
  },
  // created() {},
  mounted() {
    this.__bindingInViewEvent()
  },

  methods: {
    __bindingInViewEvent() {
      inView(`.vc-promotional-article-product-card.product-${this.productId}`).once('enter', this.__fetchProduct)
    },

    __fetchProduct() {
      if (this.product.isDataLoaded()) {
        this.isLoading = false
        this._removeRawData() // workaround that product somehow got fetched already before inview trigger
      } else {
        if (this.salesEventId) {
          return this.$store
            .dispatch('salesEvents/find', this.salesEventId)
            .then((_) => {
              return this.$store.dispatch('salesEvents/findProduct', {
                model: this.salesEvent,
                productId: this.productId,
              })
            })
            .catch((_) => {
              this.isNotFound = true
            })
            .finally((_) => {
              this.isLoading = false
              this._removeRawData()
            })
        } else {
          return this.$store
            .dispatch('products/find', this.productId)
            .catch((_) => {
              this.isNotFound = true
            })
            .finally((_) => {
              this.isLoading = false
              this._removeRawData()
            })
        }
      }
    },

    _removeRawData() {
      let rawData = this.$refs['raw-data']
      if (rawData) rawData.remove()
    },
  },
}
</script>
