import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'user_credit_cards',
  attributes: ['id', 'gateway_provider', 'payment_type', 'is_default', 'last_four', 'card_type', 'bank_name'],
  editableAttributes: ['gateway_provider', 'payment_type', 'is_default', 'prime'],
}

export default class UserCreditCard extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static find(id, options = {}) {
    return axios.get(
      `${new this().apiBasePath({
        withResourceType: false,
      })}/users/credit_cards/${id}?${FetchingDataOptionsService.call(options)}`
    )
  }

  save() {
    let BasePath = `${this.apiBasePath({ withResourceType: false })}/users/credit_cards`
    if (this.isNewRecord()) {
      return axios.post(`${BasePath}`, this.requestBody())
    }
    return axios.put(`${BasePath}/${this.id}`, this.requestBody())
  }

  setDefault() {
    return axios.post(
      `${this.apiBasePath({ withResourceType: false })}/users/credit_cards/${this.id}/set_default`,
      this.requestBody()
    )
  }

  destroy() {
    return axios.delete(`${this.apiBasePath({ withResourceType: false })}/users/credit_cards/${this.id}`)
  }

  fullCardNo() {
    return `xxxx xxxx xxxx ${this.last_four}`
  }
}
